import React, {useState} from 'react';
import {motion, useAnimation} from "framer-motion";
import ContentPool from "./pool";

var layoutToContent = {}

const show = {
    opacity: 1,
    scale: 1,
    filter: "blur(0px)",
    transition: {
        type: "tween",
        duration: 1,
    }
}

function Content({layout}) {
    const hide = {
        opacity: 0.6,
        scale: 0.9,
        filter: "blur(60px)",
        transition: {
            type: "tween",
            delay: Math.floor(Math.random() * 30 + 5),
            duration: 1,
        }
    }

    var content = layoutToContent.hasOwnProperty(layout.id)
        ? layoutToContent[layout.id]
        : ContentPool.acquireContent(layout.size)

    layoutToContent[layout.id] = content

    const [visual, setVisual] = useState(show)
    const controls = useAnimation();
    controls.start(visual)

    return (
        <motion.div
            className={content.size}
            initial={hide}
            animate={controls}
            onAnimationComplete={() => {
                if (visual.opacity === 1){
                    setVisual(hide)
                } else if(visual.opacity === 0.6){
                    var oldContent = layoutToContent[layout.id]
                    layoutToContent[layout.id] = ContentPool.acquireContent(content.size)
                    ContentPool.releaseContent(oldContent)
                    setVisual(show)
                }
            }}>
            {
                content.type === "video"
                ? <video src={content.source} className="gridImg" autoPlay muted loop/>
                : <img className="gridImg" src={content.source} alt={content.size}/>
            }
        </motion.div>
    )
}

export default Content;
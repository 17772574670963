import React, {useState} from "react";
import Content from "../Content";
import gridLayouts from "./layouts";
import '../style.css'

function Grid() {
    const [gridItem] = useState(gridLayouts);
    return (
        <div className="dynamic-grid">
            {gridItem.map((layout) => {return <Content layout={layout} key={layout.id}/>})}
        </div>
    );
}

export default Grid;
import React, {useState} from "react";
//Components
import Grid from "./Grid";
import Contact from "./Contact";
import MailchimpSubscribe from "react-mailchimp-subscribe"
//Assets
import logo from "../../assets/logo_1_w.png"

function Header() {

    const url = "https://Heircut.us1.list-manage.com/subscribe/post?u=44914ac1163e361a1c4f1cd95&amp;id=d211966fa9"
    const CustomForm = ({ status, message, onValidated }) => {
      let email, fname, lname;
      const submit = () =>
        email &&
        fname &&
        lname &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
          FNAME: fname.value,
          LNAME: lname.value
        });

        if (message !== null && message.indexOf("<a") > -1){
          message = message.substring(0,message.indexOf("<a"))
        } else if (message !== null && message.indexOf("1 -") > -1){
          message = "First name required"
        } else if (message !== null && message.indexOf("2 -") > -1){
          message = "Last name required"
        }

        function getFname(){
          if(localStorage.getItem("fname") != null){
            return localStorage.getItem("fname")
          }
        }
        function getLname(){
          if(localStorage.getItem("lname") != null){
            return localStorage.getItem("lname")
          }
        }

        function getEmail(){
          if(localStorage.getItem("email") != null){
            return localStorage.getItem("email")
          }
        }

        return (
          <div className="mailchimp-form">
            <div className="mailchimp-fullname">
              <input id="fname" ref={node => (fname = node)} type="text" placeholder="First Name" onChange={() => localStorage.setItem("fname", document.getElementById("fname").value)} defaultValue={getFname()}/>
              <input id="lname"ref={node => (lname = node)} type="text" placeholder="Last Name" onChange={() => localStorage.setItem("lname", document.getElementById("lname").value)} defaultValue={getLname()}/>
            </div>
           <input id="email" ref={node => (email = node)} type="email" placeholder="Email" onChange={() => localStorage.setItem("email", document.getElementById("email").value)} defaultValue={getEmail()}/>
            <button onClick={submit}>Subscribe</button>
            <div className="mailchimp-status">
                {status === "sending" && <p>Sending</p>}
                {status === "error" && (<p dangerouslySetInnerHTML={{ __html: message }}/>)}
                {status === "success" && (<p dangerouslySetInnerHTML={{ __html: message }}/>)}
            </div>
          </div>
        );
    };
    const [isOpen, setIsOpen] = useState(false)
    return(
        <header>
            <div className="dynamic-overlay" onClick={() => setIsOpen(isOpen => false)}>
                <div className="dynamic-content col-12 col-xs-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 col-xxl-7">
                    <img className="logo d-inline-block" src={logo} alt="Heircut's Logo"/>
                    <h1>Barbershop <br/>Experience <span className="text-warning">Reimagined</span></h1>
                    <p><span className="text-warning">Our team</span> is serving up a suite of tools that will level up the barbershop experience, <span className="text-warning">unlocking features once considered sci-fi.</span></p>
                    <p>No hack. <span className="text-warning">Come along on this fantastic voyage.</span></p>
                    <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                        />
                    )}
                    />
                    <p className="text-white-50"><small>Copyright © 2021 Heircut</small></p>
                </div>
            </div>
            <Grid/>
            <Contact setIsOpen={setIsOpen} isOpen={isOpen}/>
        </header>
    );
}

export default Header;
import grid_1 from '../../../assets/grid_1.jpg';
import grid_2 from '../../../assets/grid_2.jpg';
import grid_3 from '../../../assets/grid_3.jpg';
import grid_4 from '../../../assets/grid_4.jpg';
import grid_5 from '../../../assets/grid_5.jpg';
import grid_6 from '../../../assets/grid_6.jpg';
import grid_7 from '../../../assets/grid_7.mp4';
import grid_8 from '../../../assets/grid_8.jpg';
import grid_9 from '../../../assets/grid_9.jpg';
import grid_10 from '../../../assets/grid_10.jpg';
import grid_11 from '../../../assets/grid_11.jpg';
import grid_12 from '../../../assets/grid_12.mp4';
import grid_13 from '../../../assets/grid_13.jpg';
import grid_14 from '../../../assets/grid_14.jpg';
import grid_15 from '../../../assets/grid_15.mp4';
import grid_16 from '../../../assets/grid_16.jpg';
import grid_17 from '../../../assets/grid_17.jpg';
import grid_18 from '../../../assets/grid_18.jpg';
import grid_19 from '../../../assets/grid_19.jpg';
import grid_20 from '../../../assets/grid_20.jpg';
import grid_21 from '../../../assets/grid_21.jpg';
import grid_22 from '../../../assets/grid_22.jpg';
import grid_23 from '../../../assets/grid_23.jpg';
import grid_24 from '../../../assets/grid_24.mp4';
import grid_25 from '../../../assets/grid_25.mp4';
import grid_26 from '../../../assets/grid_26.mp4';

var _content = [
    {id:0, source: grid_1, type:"image", size: "small"},
    {id:1, source: grid_2, type:"image", size: "small"},
    {id:2, source: grid_3, type:"image", size: "small"},
    {id:3, source: grid_4, type:"image", size: "small"},
    {id:4, source: grid_5, type:"image", size: "small"},
    {id:5, source: grid_6, type:"image", size: "small"},
    {id:6, source: grid_7, type:"video", size: "tall"},
    {id:7, source: grid_8, type:"image", size: "tall"},
    {id:8, source: grid_9, type:"image", size: "small"},
    {id:9, source: grid_10, type:"image", size: "small"},
    {id:10, source: grid_11, type:"image", size: "small"},
    {id:11, source: grid_12, type:"video", size: "large"},
    {id:12, source: grid_13, type:"image", size: "small"},
    {id:13, source: grid_14, type:"image", size: "small"},
    {id:14, source: grid_15, type:"video", size: "large"},
    {id:15, source: grid_16, type:"image", size: "small"},
    {id:16, source: grid_17, type:"image", size: "small"},
    {id:17, source: grid_18, type:"image", size: "small"},
    {id:18, source: grid_19, type:"image", size: "small"},
    {id:19, source: grid_20, type:"image", size: "large"},
    {id:20, source: grid_21, type:"image", size: "large"},
    {id:21, source: grid_22, type:"image", size: "small"},
    {id:22, source: grid_23, type:"image", size: "small"},
    {id:23, source: grid_24, type:"video", size: "large"},
    {id:24, source: grid_25, type:"video", size: "tall"},
    {id:25, source: grid_26, type:"video", size: "tall"},
]

class ContentPool {
    static acquireContent(size) {
        var filteredContent = _content.filter(c => c.size === size)
        var index = Math.floor(Math.random() * filteredContent.length)
        var selection = filteredContent[index]
        _content.splice(_content.indexOf(selection), 1)
        return selection
    }

    static releaseContent(content) {
        _content.push(content)
    }
}

export default ContentPool
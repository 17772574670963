import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
//Pages
import Home from './pages/Home'
// Style
import './App.css';

function App() {
    return (
      <div>
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
            </Switch>
        </Router>
      </div>
    );
}

export default App;
import React from "react";
import { motion } from "framer-motion"

const variants = {
    open: { opacity: 1, display: "block" },
    closed: { opacity: 0, display: "none" },
}

function Contact({setIsOpen,isOpen}) {
    return(
        <div>
        <motion.div
        initial={variants.closed}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
        transition={{ type: 'tween' }}
        className="contact-window"
        >
            <h6>Interested in reaching us directly?</h6>
            <p className="m-0">Contact us at  <span className="text-warning">info@heircut.com</span><br/>for more information.</p>
        </motion.div>
        <div onClick={() => setIsOpen(isOpen => !isOpen)} className="contact-button">?</div>
        </div>
    );
}
    
export default Contact;
const gridLayouts = [
    {id:0, type:"video", size: "tall"},
    {id:1, type:"image", size: "small"},
    {id:2, type:"image", size: "small"},
    {id:3, type:"video", size: "large"},
    {id:4, type:"image", size: "small"},
    {id:5, type:"image", size: "small"},
    {id:6, type:"image", size: "small"},
    {id:7, type:"image", size: "small"},
    {id:8, type:"image", size: "small"},
    {id:9, type:"video", size: "large"},
    {id:10, type:"image", size: "small"},
    {id:11, type:"image", size: "small"},
    {id:12, type:"video", size: "tall"},
    {id:13, type:"image", size: "small"},
    {id:14, type:"image", size: "small"},
    {id:15, type:"image", size: "small"},
]

export default gridLayouts